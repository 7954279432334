<template>
	<div v-if="!islogin" id="login" class="login-page">
		<div class="form">
			<img class="logo" src="../assets/logo_01.png" alt="logo">
			<form class="login-form" @submit.prevent="signIn">
				<input type="text" v-model="username" placeholder="User Name" />
				<input type="password" v-model="password" placeholder="Password" />
				<div class="signin-button">
					<p class="message"><a href="#"
							@click="this.alertMessage('If you forgot your password, please contact the admin for assistance.')">Forgotten
							Password?</a></p>
					<button @click="signIn">Sign In</button>
				</div>
				<p id="login-message" class="message" @click="this.alertMessage()"></p>
			</form>
		</div>
	</div>
</template>

<script>
import api from '../api/api.js'
import Cookies from 'js-cookie';
export default {
	name: 'LoginPage',
	data() {
		return {
			username: '',
			password: '',
			sid: '',
			islogin: true
		}
	},
	methods: {
		alertMessage(message) {
			document.getElementById("login-message").textContent = message;
		},

		async signIn() {
			this.alertMessage();
			try {
				const response = await api.postLogin(this.username, this.password);
				if (response.data[0].attributes.extra.session) {
					//LOGIN SUCCESS
					Cookies.set('session', response.data[0].attributes.extra.session[0].session_id, { expires: 1 });
					Cookies.set('uid', response.data[0].attributes.extra.uid, { expires: 1 });
					this.$router.push({ path: '/home' })
				} else {
					this.alertMessage('Username and password do not match or you do not have an account yet.');
				}
			} catch (error) {
				this.alertMessage('LOGIN FAIL');
				console.error(error);
			}
		},
	},
	async mounted() {
		this.islogin = await api.loginStatusChecker();
		if (this.islogin) {
			this.$router.push({ path: '/home' })
		}
	}
}
</script>

<style>
#login body {
	background-color: #FFFFFF;
	font-family: "Roboto", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.login-page {
	width: 450px;
	padding: 8% 0 0;
	margin: auto;
}

.form input {
	font-family: "Roboto", sans-serif;
	outline: 0;
	width: 100%;
	border: 0;
	margin: 0 0 15px;
	padding: 10px;
	box-sizing: border-box;
	font-size: 24px;
	border-bottom: 1px solid gray;
	background-color: transparent;
}

.form button {
	font-family: "Roboto", sans-serif;
	outline: 0;
	background: #F58A32;
	width: 30%;
	border: 0;
	padding: 15px 15px;
	color: #FFFFFF;
	font-size: 17px;
	transition: all 0.3 ease;
	cursor: pointer;
	border-radius: 35px;
	font-weight: bold;
}

.form button:hover,
.form button:active,
.form button:focus {
	background: #FA9539;
}

.form .message {
	margin: 15px 0 0;
	color: #b3b3b3;
	font-size: 18px;
}

.form .message a {
	color: gray;
	text-decoration: none;
}

.form .signin-button {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0 20px;
}

#login .logo {
	width: 250px;
	height: 250px;
	position: relative;
	margin-bottom: 30px;
}
</style>