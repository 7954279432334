<template>
    <div class="upload-document">
        <transition name="popup">
            <div class="popup-mask" v-if="isShowPopup == true">
                <div class="popup-content">
                    <i class="close bi bi-x" @click="clearFile()"></i>
                    <div class="title">Upload new document</div>
                    <div class="content">
                        <div ref="drag" class="drag" v-show="Object.getOwnPropertyNames(fileData).length == 0">
                            <div class="drag-box">
                                <div class="drag-icon-box">
                                    <img src="../../assets/upload_icon.png" alt="upload">
                                </div>
                                <div class="drag-message">
                                    <span class="drag-message-title">Drag and drop an image here</span>
                                    <p>or</p>
                                    <label class="drag-message-label">
                                        <input id="upload-bar-input" type="file"
                                            accept="application/pdf,text/plain,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                            style="display: none;" @change="onFileChanged()">
                                        <label for="upload-bar-input">
                                            <div class="drag-message-manual">Select a file</div>
                                        </label>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div v-show="Object.getOwnPropertyNames(fileData).length != 0" class="file-info">
                            <div class="file-detail">
                                <div id="upload-bar-preview" class="upload-bar-preview">Empty File Input</div>
                                <div class="file-detail-privacy">
                                    <h6>Set to private</h6>
                                    <div class="form-check form-switch">
                                        <input class="form-check-input" type="checkbox" id="file-detail-privacy-input"
                                            v-model="privateFlag">
                                    </div>
                                </div>
                                <div class="document-summary">
                                    <div class="ai-generate">
                                        <p>Document Summary</p>
                                        <!-- <p>AI-Recommended Title and Description</p> -->
                                        <button class="ai-generate-button"
                                            @click="aiGenerateTitleDescription(this.uploadText)"
                                            @mouseover="showTitleTip" @mouseout="isShowTitleHint = false">
                                            <div v-if="!isTitleAiLoading"><i
                                                    class="bi bi-star-fill"></i>&nbsp;&nbsp;&nbsp;AI suggest</div>
                                            <div v-if="isTitleAiLoading" class="loading-box">
                                                <div class="loading"></div>AI幫你諗...
                                            </div>
                                        </button>
                                    </div>
                                    <div class="file-detail-title">
                                        <h6>Title:</h6>
                                        <input type="text" v-model="fileTitle" :disabled="isTitleAiLoading" />
                                    </div>
                                    <div class="file-detail-description">
                                        <h6>Description:</h6>
                                        <div class="file-detail-description-textarea"><textarea class="scrollbar"
                                                type="text" rows="4" v-model="fileDescription"
                                                :disabled="isTitleAiLoading"></textarea></div>
                                    </div>
                                </div>
                                <div v-if="!privateFlag">
                                    <div class="file-detail-permission">
                                        <h6>Permission List</h6>
                                        <div class="ai-generate">
                                            <p></p>
                                            <!-- <p>AI-recommended permission levels</p> -->
                                            <button class="ai-generate-button"
                                                @click="aiGenerateAccessGroup(this.uploadText)"
                                                @mouseover="showGroupTip" @mouseout="isShowGroupHint = false">
                                                <div v-if="!isGroupAiLoading"><i
                                                        class="bi bi-star-fill"></i>&nbsp;&nbsp;&nbsp;AI suggest
                                                </div>
                                                <div v-if="isGroupAiLoading" class="loading-box">
                                                    <div class="loading"></div>AI幫你諗...
                                                </div>
                                            </button>
                                        </div>
                                        <div
                                            :class="{ 'ai-permission-reason-outer': true, 'reason-loading': isGroupAiLoading || !permissionReason }">
                                            <div class="ai-permission-reason scrollbar">{{ permissionReason }}</div>
                                        </div>
                                        <div class="flex-space-between">
                                            <div>
                                                <select id="group-dept" v-model="selectedGroupDept"
                                                    :disabled="isGroupAiLoading"
                                                    :style="{ background: isGroupAiLoading ? 'linear-gradient(to left, #71AAE7, #FA9539, #C4E2FE)' : '#FFFFFF' }">
                                                    <option v-for="group in groupList" :key="group.id">{{ group.dept }}
                                                    </option>
                                                </select>
                                                <select id="group-level" v-model="selectedGroupLevel"
                                                    :disabled="isGroupAiLoading"
                                                    :style="{ background: isGroupAiLoading ? 'linear-gradient(to left, #71AAE7, #FA9539, #C4E2FE)' : '#FFFFFF' }">
                                                    <option v-for="level in availableGroupLevels" :key="level"
                                                        :value="level">
                                                        Level {{ level }}
                                                    </option>
                                                </select>
                                            </div>
                                            <button
                                                :class="{ 'button': true, 'add-button': true, 'disable-button-colored': isGroupAiLoading }"
                                                @click="addPermission()" :disabled="isGroupAiLoading">
                                                <div v-if="!isGroupAiLoading">+</div>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="file-detail-perimission-list">
                                        <h6>Current Permission List:</h6>
                                        <ul>
                                            <li class="item" v-for="item in currentGroupList" :key="item.id">{{
                                                item.dept }} Level {{ item.lvl }} <i class="bi bi-x-lg"
                                                    @click="removePermission(item)"></i></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="button-list">
                                    <div class="button cancel-button" @click="clearFile()">Cancel</div>
                                    <div :class="{ 'button': true, 'disable-button': this.isUploading || this.isTitleAiLoading || this.isGroupAiLoading }"
                                        @click="submitFile(fileData)">Save</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="isShowTitleHint" class="tip" :style="titleHintPos">
                    Generate title and description Recommendations
                </div>
                <div v-if="isShowGroupHint" class="tip" :style="titleHintPos">
                    Generate permission levels Recommendations
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import api from '../../api/api.js';
import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf';
import pdfjsWorker from 'pdfjs-dist/legacy/build/pdf.worker.entry';
import mammoth from 'mammoth';

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export default {
    name: 'uploadDocument',
    props: {
    },
    data() {
        return {
            isShowPopup: true,
            groupList: [],
            dragFile: {},
            fileType: '',

            fileData: {},
            fileName: '',
            mediaTypes: '',
            fileContent: '',
            fileTitle: '',
            fileDescription: '',
            privateFlag: false,
            currentGroupList: [],
            permissionReason: '',

            uploadText: '',

            messageBox: null,
            inputBox: null,

            selectedGroupDept: null,
            selectedGroupLevel: null,

            isTitleAiLoading: false,
            isGroupAiLoading: false,
            isUploading: false,

            mouseX: 0,
            mouseY: 0,
            isShowTitleHint: false,
            titleHintPos: {
                top: '0px',
                left: '0px'
            },
            isShowGroupHint: false,
        };
    },
    methods: {
        onFileChanged() {
            if (this.inputBox.files.length > 0) {
                if (this.checkFileSize(this.inputBox)) {
                    this.messageBox.textContent = this.inputBox.files[0].name;
                    this.onSelectChange(this.inputBox);
                } else {
                    this.messageBox.textContent = "Max file size is 6MB";
                    this.fileData = {};
                }
            } else if (this.dragFile.files.length > 0) {
                if (this.checkFileSize(this.dragFile)) {
                    this.messageBox.textContent = this.dragFile.files[0].name;
                    this.onSelectChange(this.dragFile);
                } else {
                    this.messageBox.textContent = "Max file size is 6MB";
                    this.fileData = {};
                }
            } else {
                this.messageBox.textContent = "No file selected";
                this.fileData = {};
            }
        },

        checkFileSize(input) {
            if (input.files && input.files[0]) {
                var fileSize = input.files[0].size;
                var maxSize = 1024 * 1024 * 6; // 6MB

                if (fileSize > maxSize) {
                    alert("Max file size is 6MB!");
                    input.value = "";
                    return false;
                }
                return true;
            }
        },

        onSelectChange(ev) {
            if (ev.files.length > 0) {
                this.fileType = ev.files[0].type;
                this.mediaTypes = this.fileType;
                if (this.fileType === "application/pdf") {
                    this.extractTextFromPdf(ev.files[0]);
                } else if (this.fileType === "text/plain") {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        this.uploadText = e.target.result;
                        this.aiGenerateTitleDescription(this.uploadText);
                        this.aiGenerateAccessGroup(this.uploadText);
                    };
                    reader.readAsText(ev.files[0]);
                } else if (this.fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
                    const reader = new FileReader();
                    reader.onload = async (e) => {
                        const arrayBuffer = e.target.result;
                        const result = await mammoth.extractRawText({ arrayBuffer });
                        this.uploadText = result.value;
                        this.aiGenerateTitleDescription(this.uploadText);
                        this.aiGenerateAccessGroup(this.uploadText);
                    };
                    reader.readAsArrayBuffer(ev.files[0]);
                } else {
                    this.mediaTypes = '';
                    this.fileType = '';
                    this.messageBox.textContent = "The file type is not supported";
                    return;
                }
                this.fileName = ev.files[0].name;

                let reader = new FileReader();
                reader.readAsDataURL(ev.files[0]);
                reader.onloadend = rev => {
                    this.fileContent = rev.target.result.replace('data:', '').replace(/^.+,/, '');
                    const data = {
                        "name": this.fileName,
                        "mediatypes": this.mediaTypes,
                        "content": this.fileContent
                    };
                    this.fileData = data;
                    this.inputBox.value = '';
                };
            } else {
                console.log("no file selected");
            }
        },

        addPermission() {
            if (this.selectedGroupDept && this.selectedGroupLevel) {
                const alreadyExists = this.currentGroupList.some(item =>
                    item.dept === this.selectedGroupDept && item.lvl === this.selectedGroupLevel
                );
                if (!alreadyExists) {
                    this.currentGroupList.push({
                        dept: this.selectedGroupDept,
                        lvl: this.selectedGroupLevel,
                    })
                }
            }
            this.removeSameDeptGroup();
        },

        removeSameDeptGroup() {
            this.currentGroupList = Object.values(this.currentGroupList.reduce((acc, item) => {
                acc[item.dept] = item;
                return acc;
            }, {}));
        },

        removePermission(item) {
            this.currentGroupList = this.currentGroupList.filter(i => i !== item);
        },

        clearFile() {
            this.fileData = {};
            this.fileContent = '';
            this.fileName = '';
            this.fileType = '';
            this.mediaTypes = '';
            this.fileTitle = '';
            this.fileDescription = '';
            this.currentGroupList = [];
            this.selectedGroupDept = '';
            this.selectedGroupLevel = '';
            this.privateFlag = false;
            this.inputBox.value = '';
            this.uploadText = '';
            this.permissionReason = '';
            this.messageBox.textContent = "No file selected";

            this.closePopup();
        },

        closePopup() {
            this.isShowPopup = false;
            this.$emit('toggleUploadPopup');
        },

        async submitFile() {
            if (!this.fileTitle) {
                alert("File title should not be empty");
                return;
            }
            if (!this.privateFlag && this.currentGroupList.length == 0) {
                alert("Please select at least one group");
                return;
            }
            if (this.isUploading) {
                return;
            }
            this.isUploading = true;
            const data = {
                "base64Content": this.fileContent,
                "fileType": this.mediaTypes,
                "fileName": this.fileName,
                "fileTitle": this.fileTitle,
                "fileDescription": this.fileDescription,
                "fileAccessGroups": this.privateFlag ? [] : this.currentGroupList,
                "privateFlag": this.privateFlag ? 1 : 0,
            };
            const response = await api.postUploadFile(data);
            if (!response.error) {
                // this.$router.go(0);
                this.isUploading = false;
                this.$emit('refreshList');
                this.closePopup();
            } else {
                console.error(response);
            }
        },

        bindEvents() {
            const drag = this.$refs.drag
            drag.addEventListener('dragover', e => {
                e.preventDefault();
                drag.style.borderColor = '#F58A32';
            })
            drag.addEventListener('dragleave', e => {
                e.preventDefault();
                drag.style.borderColor = '#a3a3a3';
            })
            drag.addEventListener('drop', e => {
                e.preventDefault();
                drag.style.borderColor = '#a3a3a3';
                this.dragFile = e.dataTransfer;
                this.onFileChanged();
            })
        },

        async getAceessGroupList() {
            const response = await api.getDocumentAceessGroupList();
            if (!response.error && response.data[0].attributes.extra.ricoh) {
                this.groupList = response.data[0].attributes.extra.ricoh.result;

                const uniqueGroups = this.groupList.reduce((acc, item) => {
                    const existingGroup = acc.find(group => group.dept === item.group_dept);
                    if (existingGroup) {
                        existingGroup.lvl.push(item.group_level);
                    } else {
                        acc.push({ dept: item.group_dept, lvl: [item.group_level] });
                    }
                    return acc;
                }, []);
                this.groupList = uniqueGroups;
            }
        },

        async aiGenerateTitleDescription(prompt = '') {
            if (this.isTitleAiLoading == true) {
                return;
            }
            this.isTitleAiLoading = true;
            let response;
            if (prompt === '') {
                response = await api.postAskTitleDescription("help me to generate another title and some realtive description, reply me a json string only");
            } else {
                response = await api.postAskTitleDescription(prompt);
            }
            this.isTitleAiLoading = false;
            let result;
            if (!response.error) {
                try {
                    // result = JSON.parse("["+response.data[0].attributes.extra.ricoh.result.content.replace(/'/g, '"')+"]")[0];
                    const jsonString = response.data[0].attributes.extra.ricoh.result.content.match(/```json({.*})```|({.*})/s)[0];
                    result = JSON.parse("[" + jsonString + "]")[0];
                }
                catch (error) {
                    console.error('JSON parse error');
                }
            } else {
                console.error(response);
            }
            if (result) {
                if (result.title) this.fileTitle = result.title;
                if (result.description) this.fileDescription = result.description;
            } else {
                alert('Failed to generate title and description. Please try again.');
            }
        },

        async aiGenerateAccessGroup(prompt = '', retry = 0) {
            if (this.isGroupAiLoading == true) {
                return;
            }
            this.isGroupAiLoading = true;
            this.permissionReason = '';
            let response;
            if (prompt === '') {
                response = await api.postAskAccessGroup("help me to generate some different access group. Reply me a json string only. The json should contain a 'dept' field and a 'lvl' field. The 'dept' field should be a string and the 'lvl' field should be a number. For example, the reply should be like this: [{\"dept\": \"HR\", \"lvl\": 1},{\"dept\": \"HR\", \"lvl\": 2},{\"dept\": \"IT\", \"lvl\": 1}]. The maximuim length of the array is 5. The maximuim lvl is 3. Each item should only has one department and one lvl. Reply me a json string only. Your reply should not contain any other text or information.");
            } else {
                response = await api.postAskAccessGroup(prompt);
            }
            this.isGroupAiLoading = false;
            let result;
            if (!response.error) {
                try {
                    // result = JSON.parse(response.data[0].attributes.extra.ricoh.result.content.replace(/'/g, '"'));
                    const jsonString = response.data[0].attributes.extra.ricoh.result.content.match(/```json({.*})```|({.*})/s)[0];
                    result = JSON.parse("[" + jsonString + "]")[0];
                }
                catch (error) {
                    console.error('JSON parse error');
                }
            } else {
                console.error(response);
            }
            if (result) {
                const filterResult = this.groupList
                    .filter(item => result.access_departments.includes(item.dept) && item.lvl.includes(parseInt(result.access_level)))
                    .map(item => ({ dept: item.dept, lvl: result.access_level }));
                if (filterResult.length > 0) {
                    filterResult.forEach(newMatch => {
                        const alreadyExists = this.currentGroupList.some(item =>
                            item.dept === newMatch.dept && item.lvl === newMatch.lvl
                        );
                        if (!alreadyExists) {
                            this.currentGroupList.push(newMatch);
                        }
                    });
                    this.removeSameDeptGroup();
                    this.permissionReason = result.reason;
                } else {
                    if (retry < 3) {
                        console.log('No new matching access group found. Trying. Automatically regenerating ' + (retry + 1) + '...');
                        this.aiGenerateAccessGroup(prompt, retry + 1);
                    } else {
                        alert('No new matching access group found. You can try to generate again.');
                    }
                }
            }
        },

        async extractTextFromPdf(file) {
            const reader = new FileReader();
            reader.onload = async (e) => {
                const arrayBuffer = e.target.result;
                const pdf = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
                let textContent = '';

                for (let i = 1; i <= pdf.numPages; i++) {
                    const page = await pdf.getPage(i);
                    const content = await page.getTextContent();
                    content.items.forEach(item => {
                        textContent += item.str + ' ';
                    });
                }

                this.uploadText = textContent.trim();
                this.aiGenerateTitleDescription(this.uploadText);
                this.aiGenerateAccessGroup(this.uploadText);
            };
            reader.readAsArrayBuffer(file);
        },

        getMousePosition(event) {
            this.mouseX = event.clientX;
            this.mouseY = event.clientY;
        },

        showTitleTip(event) {
            this.isShowTitleHint = true;
            this.titleHintPos.top = `${event.clientY + 15}px`;
            this.titleHintPos.left = `${event.clientX - 30}px`;
        },

        showGroupTip(event) {
            this.isShowGroupHint = true;
            this.titleHintPos.top = `${event.clientY + 15}px`;
            this.titleHintPos.left = `${event.clientX - 30}px`;
        },
    },

    computed: {
        availableGroupLevels() {
            if (this.selectedGroupDept !== null) {
                const selectedGroup = this.groupList.find(
                    (group) => group.dept === this.selectedGroupDept
                );
                return selectedGroup ? selectedGroup.lvl : [];
            }
            return [];
        },
    },

    mounted() {
        this.bindEvents();
        this.messageBox = document.querySelector("#upload-bar-preview");
        this.inputBox = document.querySelector("#upload-bar-input");
        this.getAceessGroupList();
    }
}
</script>

<style scoped>
.flex-space-between {
    display: flex;
    justify-content: space-between;
}

.popup-mask {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
}

.popup-mask .popup-content {
    position: absolute;
    width: 30%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    background: #fff;
    overflow: hidden;
    z-index: 1000;
    border-radius: 30px;
    padding: 20px;
    min-width: 530px;
    max-height: 100%;
    overflow-y: scroll;
}

.popup-mask .popup-content::-webkit-scrollbar {
    display: none;
}

.popup-mask .popup-content .close {
    border-radius: 40px;
    width: 18px;
    font-size: 20px;
    float: right;
    color: gray;
    position: absolute;
    right: 23px;
    top: 13px;
    cursor: pointer;
}

.popup-mask .popup-content .title {
    margin: 20px 0;
    font-weight: bold;
    font-size: 15px;
}

.drag {
    width: 95%;
    height: 150px;
    border: 2px dashed;
    border-color: #a3a3a3;
    border-radius: 30px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    background-color: lightgray;
}

.drag .drag-box {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
}

.drag .drag-icon-box {
    height: 60px;
    text-align: center;
    font-size: 50px;
    line-height: 60px;
    color: #606266;
}

.drag .drag-icon-box img {
    width: 65px;
}

.drag .drag-message {
    min-inline-size: fit-content;
    line-height: 20px;
    text-align: center;
}

.drag .drag-message .drag-message-title {
    font-size: 14px;
    color: #606266;
    font-weight: bold;
}

.drag .drag-message p {
    margin: 0;
    font-weight: lighter;
}

.drag .drag-message .drag-message-label {
    height: auto;
    position: relative;
    overflow: hidden;
}

.drag .drag-message .drag-message-label .drag-message-input {
    position: absolute;
    left: -100px;
    top: -100px;
    z-index: -1;
    display: none;
}

.drag .drag-message .drag-message-label .drag-message-manual {
    border-radius: 10px;
    font-size: 14px;
    color: #F58A32;
    cursor: pointer;
    padding: 0 20px;
    font-weight: bold;
}

.content .file-detail .upload-bar-preview {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: dodgerblue;
}

.content .file-detail .button {
    border-radius: 20px;
    font-size: 14px;
    color: white;
    cursor: pointer;
    background-color: #F58A32;
    padding: 0px 10px;
    border: unset;
    width: 15%;
    margin: 5px 5px;
    line-height: 35px;
    font-weight: bold;
}

.content .file-detail .button:hover {
    background-color: #F05A27;
}

.content .file-detail .button:active {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    transform: scale(0.99);
}

.content .file-detail .button.disable-button {
    background-color: darkgray;
    cursor: default;
}

.content .file-detail .button.cancel-button {
    background-color: transparent;
    color: gray;
}

.content .file-info {
    width: 100%;
    position: relative;
    top: -15px;
    padding: 0 10px;
}

.content .file-detail h6 {
    margin: 0;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: bold;
}

.content .file-detail .file-detail-title {
    text-align: left;
}

.content .file-detail .file-detail-title input {
    width: 100%;
    border: unset;
    outline: unset;
    border-bottom: 1px solid gray;
    padding: 10px 15px;
    font-size: 14px;
    background-color: transparent;
}

.content .file-detail .file-detail-description {
    margin-top: 15px;
    text-align: left;
}

.content .file-detail .file-detail-description .file-detail-description-textarea {
    width: 100%;
    border-bottom: 1px solid gray;
    padding: 5px 0px;
    font-size: 14px;
    display: flex;
}

.content .file-detail .file-detail-description .file-detail-description-textarea textarea {
    width: 100%;
    border: none;
    resize: none;
    outline: none;
    padding: 5px 15px;
    align-content: end;
    background-color: transparent;
}

.content .file-detail .file-detail-privacy {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 10px 0;
    padding: 10px;
    background-color: #F7F7F7;
    border-radius: 10px;
}

.content .file-detail .file-detail-privacy .form-switch .form-check-input {
    box-shadow: unset;
    border: 1px solid gray;
    background-color: gray;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%28255, 255, 255, 1%29%27/%3e%3c/svg%3e")
}

.content .file-detail .file-detail-privacy .form-switch .form-check-input:checked {
    border: 1px solid #F58A32;
    background-color: #F58A32;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%28255, 255, 255, 1%29%27/%3e%3c/svg%3e")
}

.content .file-detail .file-detail-permission {
    margin-top: 10px;
    text-align: left;
    font-size: 12px;
    background-color: #F7F7F7;
    padding: 10px;
    border-radius: 10px 10px 0 0;
}

.content .file-detail .file-detail-permission .ai-permission-reason-outer {
    margin: 4px 0 12px 0;
    background: transparent;
    border-bottom: 1px solid gray;
    padding: 5px 15px;
}

.content .file-detail .file-detail-permission .ai-permission-reason-outer.reason-loading {
    min-height: 4.2em;
    border: unset;
    border-radius: 10px;
    background: linear-gradient(to left, #71AAE7, #FA9539, #C4E2FE);
}

.content .file-detail .file-detail-permission .ai-permission-reason {
    overflow-y: scroll;
    max-height: 4.2em;
    line-height: 1.4em;
}

.content .file-detail .file-detail-permission select {
    margin: 0 10px 0 0;
    min-width: 150px;
    border: 1px solid gray;
    border-radius: 15px;
    height: 1.5rem;
    padding: 0px 10px;
}

.content .file-detail .file-detail-permission .add-button {
    padding: 0;
    width: 115px;
    margin: 0;
    line-height: normal;
    border-radius: 20px;
}

.content .file-detail .file-detail-permission .add-button.disable-button-colored {
    background: linear-gradient(to left, #71AAE7, #FA9539, #C4E2FE);
    cursor: not-allowed;
}

.content .file-detail .file-detail-perimission-list {
    text-align: left;
    background-color: #F7F7F7;
    padding: 10px;
    border-radius: 0 0 10px 10px;
}

.content .file-detail .file-detail-perimission-list ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
}

.content .file-detail .file-detail-perimission-list ul li {
    list-style-type: none;
    background-color: peachpuff;
    margin: 0 5px 5px 5px;
    border-radius: 20px;
    padding: 0 15px;
    color: #F05A27;
    border: 1px solid #F05A27;
    font-size: 12px;
    font-weight: bold;
}

.content .file-detail .file-detail-perimission-list ul li i {
    color: #FFFFFF;
    cursor: pointer;
}

.content .file-detail .button-list {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
}

.content .file-detail .ai-generate {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
    flex-wrap: wrap;
    align-items: center;
}

.content .file-detail .ai-generate p {
    margin: 0;
    text-align: left;
    font-size: 12px;
    width: 60%;
    font-weight: bold;
    color: gray;
}

.content .file-detail .ai-generate .ai-generate-button {
    border-radius: 20px;
    background: linear-gradient(to left, #71AAE7, #FA9539, #C4E2FE);
    color: white;
    padding: 0 10px;
    width: 115px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 12px;
    border: none;
    height: 25px;
}

.content .file-detail .ai-generate .ai-generate-button:active {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    transform: scale(0.99);
}

.content .file-detail .document-summary {
    padding: 10px;
    background-color: #F7F7F7;
    border-radius: 10px;
}

.loading-box {
    display: contents;
}

.loading {
    position: relative;
    width: 16px;
    height: 16px;
    border: 2px solid #fff;
    border-top-color: rgba(255, 255, 255, 0.2);
    border-right-color: rgba(255, 255, 255, 0.2);
    border-bottom-color: rgba(255, 255, 255, 0.2);
}

.tip {
    position: absolute;
    background-color: #DDDDDD;
    color: #666666;
    padding: 2px 10px;
    border-radius: 25px;
    z-index: 1000;
    font-size: 14px;
}

.tip:before {
    width: 0px;
    height: 0px;
    content: "";
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #DDDDDD;
    position: absolute;
    top: -10px;
    left: 50px;
}
</style>